import React from 'react';
import classNames from 'classnames';

const Section = ({
  children,
  className,
  id,
  withoutPadding = false,
  withAbstract = false
}) => (
  <section
    id={id}
    className={classNames(className, {
      'sectionWithAbstract relative before:absolute before:left-0 before:h-full before:w-full before:bg-abstract before:bg-primary before:z-negative before:tab-port:hidden':
        withAbstract,
      'py-20 px-10 phone:px-6': !withoutPadding
    })}>
    {children}
  </section>
);

export default Section;

import React from 'react';

const Associate = ({ name, role, state, image }) => (
  <figure>
    <img src={image} alt='CRO ASSOCIATE' className='rounded-lg w-full' />
    <figcaption className='w-3/4 bg-primary text-white px-1 py-3 -translate-y-1/2 mx-auto text-center rounded-lg'>
      <span className='text-xs block'>{name}</span>
      <span className='text-atmoic block'>- {role}</span>
      <span className='text-atmoic block'>{state}</span>
    </figcaption>
  </figure>
);

export default Associate;

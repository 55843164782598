import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Section from '$ui/section';
import Heading from '$ui/heading';

import Associate from '$components/associate';

const GalleryGrid = ({ children }) => (
  <div className='grid grid-cols-[repeat(auto-fit,minmax(14rem,1fr))] gap-x-5 gap-y-10 px-40 mt-10 tab-port:px-8'>
    {children}
  </div>
);

const CommitteePage = () => {
  const {
    allCloudinaryMedia: { edges: images }
  } = useStaticQuery(graphql`
    query CommiteeImagesQuery {
      allCloudinaryMedia {
        edges {
          node {
            context {
              custom {
                name
                role
                state
              }
            }
            secure_url
            folder
          }
        }
      }
    }
  `);

  const patronMemberImages = useMemo(
    () =>
      images.filter(
        image =>
          image.node.folder === 'frontend/committee/national-patron-member'
      ),
    [images]
  );

  const workingCommitteeImages = useMemo(
    () =>
      images.filter(
        image =>
          image.node.folder === 'frontend/committee/national-working-committee'
      ),
    [images]
  );

  const statePresidentImages = useMemo(
    () =>
      images.filter(
        image => image.node.folder === 'frontend/committee/state-presidents'
      ),
    [images]
  );

  return (
    <>
      <Helmet>
        <title>TheCRO &mdash; Committee</title>
      </Helmet>

      <Section id='nationalPatron' className='pt-32'>
        <Heading>Honorable national patron members</Heading>
        <GalleryGrid>
          {patronMemberImages?.map((image, i) => (
            <Associate
              key={i}
              name={image.node.context.custom.name}
              role={image.node.context.custom.role}
              image={image.node.secure_url}
              alt='PERSON'
            />
          ))}
        </GalleryGrid>
      </Section>

      <Section id='nationalWorking' className='pt-8'>
        <Heading >National working committee</Heading>
        <GalleryGrid>
          {workingCommitteeImages?.map((image, i) => (
            <Associate
              key={i}
              name={image.node.context.custom.name}
              role={image.node.context.custom.role}
              image={image.node.secure_url}
              alt='PERSON'
            />
          ))}
        </GalleryGrid>
      </Section>

      <Section id='presidents' className='pt-8'>
        <Heading>Our state presidents</Heading>
        <GalleryGrid>
          {statePresidentImages?.map((image, i) => (
            <Associate
              key={i}
              name={image.node.context.custom.name}
              role={image.node.context.custom.role}
              state={image.node.context.custom.state}
              image={image.node.secure_url}
              alt='PERSON'
            />
          ))}
        </GalleryGrid>
      </Section>
    </>
  );
};

export default CommitteePage;
